:root {
  --color-dark-light: #121212;
  --color-dark: #090A0A;
  --color-light: #F1EEDA;
  --color-pink: #A90E3D;
  --color-blue: #1D4053;
  --color-purple: #28001E;
}

.align {
  display: grid;
  place-items: center;
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column{
  flex-direction: column;
}

.flex__gap-100{
  gap: 100px;
}

.flex__gap-50{
  gap: 50px;
}

.flex__gap-20{
  gap: 20px;
}

.flex__gap-10{
  gap: 10px;
}

.justify-between{
  justify-content: space-between;
}

.c{
  z-index: 9999;
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.text-center{
  text-align: center;
}

.title-main{
  font-size: 48px;
  text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
  line-height: 1.4;
  font-weight: 700;
  text-shadow: rgb(165, 0, 0) 9px 5px 15px;
}

.subtitle-main{
  font-family: "DM Sans", Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
}

.subtitle-main > span{
  text-shadow: rgb(158, 100, 100) 1px 1px 9px;
  font-weight: bold;
  font-style: inherit;
}

.paragraph-default{
  font-size: 15px;
  font-weight: 500;
}

.paragraph-default > span{
  font-weight: bold;
}

.info-text{
  color: #d4d4d4;
  font-style: normal;
  font-weight: inherit;
  text-align: left;
  letter-spacing: 0px;
  text-shadow: none;
  font-size: 12px;
}

.text-end{
  text-align: end;
}

.text-start{
  text-align: start;
}

.mg__bottom-15{
  margin-bottom: 15px;
}

.mg__right-10{
  margin-right: 10px;
}

.full-window{
  width: 100%;
  height: 100vh;
}

.cursor-pointer{
  cursor: pointer;
}

.half-screen{
  width: 50%;
}

.background-dark{
  background-color: var(--color-dark);
  color: white;
}

.background-dark-light{
  background-color: var(--color-dark-light);
  color: white;
}

.text-white{
  color: white;
}

.padding-100{
  padding: 100px;
}

.padding-50{
  padding: 50px;
}

.flex__wrap{
  flex-wrap: wrap;
}

.text-dark{
  color: #121212;
}


.box-shadow{
  box-shadow: 1px 2px 12px 3px #00000063;
}

.box-shadow-dark{
  box-shadow: 5px 0 12px 4px #00000085;
}

.width-70{
  width: 70%;
}   

.img img{
  width: 100%;
  height: auto;
}

.img-15{
  width: 15px;
}

.img-25{
  width: 25px;
}

.img-100{
  width: 100px;
}

.img-150{
  width: 150px;
}

.img-200{
  width: 200px;
}

.img-250{
  width: 250px;
}

.img-300{
  width: 300px;
}

.img-350{
  width: 350px;
}

.img-400{
  width: 400px;
}

.img-500{
  width: 600px;
}

.btn-default{
  text-decoration: none;
  float: left;
  z-index: 11;
  background-color: transparent;
  background-image: none;
  color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  font-size: 16px;
  text-align: center;
  box-shadow: rgb(207, 207, 207) 0px 1px 3px;
  border-radius: 50px;
  padding: 15px 45px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  cursor: pointer;
  transition: 0.5s;
}

.btn-default:hover{
  background-color: var(--color-pink);
}

.z-index-top{
  z-index: 333;
}

@media (min-width: 750px) and (max-width: 1100px) {
    .img-150{
      width: 100px;
    }

    .title-main{
      font-size: 35px;
    }

    .subtitle-main{
      font-size: 20px;
    }

    .btn-default{
      font-size: 14px;
    }

    .info-text{
      font-size: 11px;
    }
}

@media (max-width: 750px) {
    .flex-center{
      flex-direction: column;
    }

    .img-100{
      width: 60px;
    }

    .img-150{
      width: 70px;
    }

    .img-250{
      width: 170px;
    }

    .img-350{
      width: 240px;
    }

    .img-300{
      width: 210px;
    }

    .img-400{
      width: 300px;
    }

    .title-main{
      font-size: 25px;
    }

    .subtitle-main{
      font-size: 16px;
    }

    .btn-default{
      font-size: 12px;
      padding: 8px 38px;
    }

    .info-text{
      font-size: 10px;
    }

    .flex__gap-50{
      gap: 25px;
    }

    .flex__gap-100{
      gap: 50px;
    }

    .padding-100{
      padding: 50px;
    }
    .half-screen{
      width: 100%;
    }

    .paragraph-default{
      font-size: 12px;
    }
}
#feedback-section{
    background-color: rgb(252, 252, 252);
    background-image: url('../Assets/background-feedbacks.webp');
    background-position: 41.4634% 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

#title-feedback-section{
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    color: rgb(36, 36, 36);
    font-style: italic;
    font-weight: 700;
    font-size: 45px;
}

#title-feedback-section > span{
    color: rgb(130, 77, 93);
    border-bottom: 2px solid rgb(130, 77, 93);
    display: inline-block;
    padding-bottom: 2px;
}

@media (max-width: 750px) {
    #title-feedback-section{
        font-size: 30px;
        text-align: center;
    }
}
.title-episode{
    color: white;
    font-family: "DM Sans", sans-serif;
    font-style: italic;
    line-height: 1.4;
    font-weight: 700;
    text-shadow: rgb(165, 0, 0) 9px 5px 15px;    
    margin-bottom: 20px;
    font-size: 25px;
}

.description-episode{
    font-family: "Poppins", Arial, Helvetica, sans-serif;

}

#img-container-episode{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 230px;
    margin-left: 120px;
    height: 500px;
}

.img-episode{
    position: absolute;
}

#img-pq img{
    filter: hue-rotate(0deg) saturate(1) brightness(1) contrast(1) invert(0) sepia(0) blur(0px) grayscale(0) drop-shadow(rgb(0, 0, 0) 0px 42px 42px);
    z-index: 1;
}

#img-despertar img{
    filter: hue-rotate(0deg) saturate(1) brightness(1) contrast(1) invert(0) sepia(0) blur(0px) grayscale(0) drop-shadow(rgb(0, 0, 0) 33px 33px 19px);
    z-index: 2;
}

#img-despertar{
    left: 5px;
    padding-top: 80px;
}


@media (max-width: 1100px) {
    #content-episode{
        flex-direction: column;
        gap: 50px;
    }

    #ep3-container{
        width: 100%;
    }

    #img-container-episode {
        order: -1;
    }

    .title-episode{
        text-align: center;
    }

    .description-episode{
        text-align: center;
    }

}

@media (min-width: 330px) and (max-width: 750px) {
    #img-despertar{
        padding-top: 40px;
    }

    .title-episode{
        font-size: 20px;
        text-align: center;
    }

    .description-episode{
        font-size: 14px;
        text-align: center;
        padding: 20px;
    }

    #img-container-episode{
        height: 350px;
    }

    #container-offer{
        padding: 30px;
    }

    #container-offer h2{
        font-size: 16px;
        width: 70%;
    }

    #bookep2{
        padding: 20px;
    }

    #bookep1{
        padding: 20px;
    }

    #img-despertar{
        left: 45px;
    }

    #img-container-episode {
        order: -1;
    }
}

@media (max-width: 330px) {
    #img-despertar{
        padding-top: 35px;
    }

    .title-episode{
        font-size: 18px;
        text-align: center;
    }

    .description-episode{
        font-size: 12px;
        text-align: center;
        padding: 20px;
    }

    #img-container-episode{
        height: 300px;
    }

    #container-offer{
        padding: 25px;
    }

    #container-offer h2{
        font-size: 14px;
        width: 50%;
    }

    #bookep2{
        padding: 15px;
    }

    #bookep1{
        padding: 15px;
    }

    #img-container-episode {
        order: -1;
    }
}
#background-youfind{
    position: relative;
    /* background-color: rgb(23, 23, 23); */
    background-image: url('../Assets/background-books.webp');
    background-position: 70.7071% 47.9798%;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

#subtitle-youfind{
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    color: white;
    font-size: 20px;
}

#title-youfind{
    font-weight: bold;
    font-style: inherit;
    font-family: "DM Sans", sans-serif;
    text-shadow: rgb(235, 84, 98) 1px 1px 1px;
    font-size: 40px;
}

.gpc-b_sobreposicao {
    opacity: 0.81;
    background-color: rgb(217, 142, 134);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

#container-cards{
    max-width: 1000px;
}

@media (max-width: 750px) {
    #title-youfind{
        font-size: 25px;
    }

    #subtitle-youfind{
        font-size: 14px;
    }
}
#bookep2{
    background-color: rgb(76,37,49);
}

.bg-red{
    background-color: rgb(94,24,24);
}

.stopwatch{
    border: 0px solid rgb(34, 34, 34);
    filter: hue-rotate(0deg) saturate(1) brightness(1) contrast(1) invert(0) sepia(0) blur(0px) grayscale(0);
    background-color: rgb(23, 23, 23);
    color: white;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.stopwatch span{
    font-size: 10px;
    color: #d4d4d4;
}

.stopwatch h2{
    font-size: 25px;
}

.img-book{
    filter: hue-rotate(0deg) saturate(1.41) brightness(1.17) contrast(1.11) invert(0) sepia(0) blur(0px) grayscale(0) drop-shadow(rgb(0, 0, 0) 32px 32px 22px);
}

#container-offer{
    border-radius: 15px;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
}

#container-offer h2{
    color: rgb(232, 160, 103);
    font-size: 20px;
}

#container-offer h1{
    font-size: 60px;
    font-family: "DM Sans", Arial, Helvetica, sans-serif
}

#container-offer span{
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    color: #d4d4d4;
    font-size: 14px;
}

.striked {
    text-decoration: line-through;
}

#container-offer hr{
    width: 60%;
    border-color: rgb(232, 160, 103);
}
@media (min-width: 800px) and (max-width: 1100px) {
    .content-book{
        gap: 43px;
    }

    #bookep1{
        padding: 50px;
    }
}

@media (max-width: 800px) {
    .stopwatch{
        flex-direction: row;
    }

    #container-offer h1{
        font-size: 45px;
    }

    .content-book{
        gap: 20px;
        flex-direction: column-reverse;
    }
}
#spoiler-section{
    background-color: rgb(23,23,23);
    color: white;
}

#title-spoiler{
    font-family: "DM Sans", sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
}

#autora-bold{
    font-weight: bold;
    border-bottom: 2px solid #e8a067
}

#spoiler-bold{
    color: var(--color-pink);
}

@media (min-width: 330px) and (max-width: 750px) {
    #title-spoiler{
        font-size: 25px;
    }
}

@media (max-width: 330px){
    #spoiler-section{
        font-size: 12px;
    }
    #title-spoiler{
        font-size: 22px;
    }
}
#title-crisworld{
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: inherit;
    color: #e8a067;
    font-size: 40px;
    font-weight: 300;
}

#title-crisworld > span{
    color: rgb(172, 86, 83);
    border-bottom: 2px solid #e8a067; 
    display: inline-block;
    padding-bottom: 2px; 
    font-weight: bold;
}

@media (min-width: 750px) and (max-width: 1100px) {
    #title-crisworld{
        font-size: 30px;
    }
}

@media (min-width: 330px) and (max-width: 750px) {
    #title-crisworld{
        font-size: 25px;
    }
}

@media (max-width: 330px) {
    #crisworld{
        text-align: center;
        padding: 25px;
    }

    #title-crisworld{
        font-size: 20px;
    }
}
.feedback-card{
    background-color: var(--color-light);
    padding: 20px 30px;
    align-items: start;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    width: 500px;
    border-radius: 12px;
}

.header-card-feedback{
    justify-content: space-between;
    width: 100%;
}

.container-title-feedback{
    align-items: start;
}

.img-feedback img{
    border-radius: 50%;
    width: 40px; 
    height: 40px; 
    object-fit: cover;
    background-color: rgb(99,129,150);
}

.name-feedback{
    color: #824d5d;
    font-size: 16px;
}

.subtitle-feedback{
    color: #242424;
    font-size: 12px;
    font-weight: 200;
}

.description-feedback{
    color: #242424;
    font-size: 13px;
}

@media (max-width: 750px) {
    .header-card-feedback{
        align-items: start !important;
    }

    #container-star{
        flex-direction: row !important;
    }

    #user-feedback-container{
        align-items: start !important;
    }

    .feedback-card{
        width: 100%;
    }

    .name-feedback{
        font-size: 14px;
    }

    .description-feedback{
        font-size: 12px;
    }

    #feedback-section{
        padding: 50px 20px;
    }
}
#meetcris{
    background-color: rgb(23, 23, 23);
}

#content-meetcris{
    align-items: start;
}
#meetcris h2{
    font-family: "DM Sans", Arial, Helvetica, sans-serif;
    color: #e8a067;
    font-weight: 700;
    font-size: 40px;    
}

#meetcris h2 > span{
    font-weight: bold;
    border-bottom: 2px solid #e8a067;
}

#meetcris p{
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    color: #d4d4d4;
    width: 550px;
}

#cris-img-meetcris{
    filter: hue-rotate(0deg) saturate(1) brightness(1) contrast(1) invert(0) sepia(0) blur(0px) grayscale(0) drop-shadow(rgb(209, 110, 110) 0px 0px 100px);
}

@media (min-width: 750px) and (max-width: 1100px) {
    #meetcris{
        gap: 35px;
        padding: 50px;
    }
    #meetcris h2{
        font-size: 25px;
    }

    #meetcris p{
        width: 380px;
        font-size: 14px;
    }

    #cris-img-meetcris{
        width: 150px;
    }
}

@media (max-width: 750px) {
    #content-meetcris{
        align-items: center;
    }
    #meetcris{
        gap: 25px;
        padding: 30px;
        text-align: center;
    }
    #meetcris h2{
        font-size: 23px;
    }

    #meetcris p{
        width: 100%;
        font-size: 12px;
    }

    #cris-img-meetcris{
        width: 120px;
    }
}
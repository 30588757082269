#warranty-section{
    background-color: rgb(231, 147, 80);
}

#content-warranty{
    width: 60%;
}

#warranty-section h2{
    font-family: "DM Sans", Arial, Helvetica, sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
}

#warranty-section p{
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-size: 18px;
}

@media (min-width: 750px) and (max-width: 1100px) {
    #warranty-section{
        padding: 45px;
    }

    #warranty-section h2{
        font-size: 26px;
    }

    #warranty-section p{
        font-size: 16px;
    }
}

@media (max-width: 750px) {
    #content-warranty{
        width: 100%;
    }

    #warranty-section{
        padding: 35px;
        align-items: start;
        gap: 25px;
    }

    #warranty-section h2{
        font-size: 24px;
    }

    #warranty-section p{
        font-size: 14px;
    }
}

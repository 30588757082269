#footer-contact{
    background-color: rgb(23, 23, 23);
    color: white;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    text-align: center;
}

#footer-contact p{
    font-size: 16px;
}

#footer-contact #line-footer{
    width: 60%;
    border-color: rgb(209, 110, 110);
}

#footer-contact span#email-label{
    font-weight: 200;
    font-size: 12px;
}

#footer-contact input{
    background-color: rgb(0, 9, 12);
    height: 50px;
    border-radius: 5px 0px 0px 5px;
    width: 400px;
    border: none;
    margin-top: 5px;
    color: white;
    padding: 15px;
}

#send-contact-btn{
    background-color: rgb(232,160,103);
    height: 50px;
    border-radius: 0px 5px 5px 0px;
    width: 50px;
    border: none;
    color: #121212;
    font-weight: 700;
    font-size: 10px;
    cursor: pointer;
}

#footer-contact #text-orange{
    color: rgb(221,147,89);
}

#footer-contact #text-red{
    color: rgb(223,74,74);
}

@media (max-width: 750px) {
    #footer-contact p {
        font-size: 12px;
    }
    
    #footer-contact{
        font-size: 12px;
    }

    #footer-contact input{
        width: 200px;
        height: 45px;
    }

    #send-contact-btn{
        height: 45px;
    }

    #footer-contact #line-footer{
        width: 100%;
    }
}

@media (max-width: 330px){
    #footer-contact input{
        width: 150px;
    }
}

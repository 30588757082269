#titles-home{
    align-items: start !important;
}

#criszuca-img-home {
    width: 300px;
    height: 100%;
    background-image: url('../Assets/criszucas.webp');
    background-size: cover;
    background-position: top;
    filter: hue-rotate(0deg) saturate(1) brightness(1) contrast(1) invert(0) sepia(0) blur(0px) grayscale(0) drop-shadow(rgb(251, 166, 166) 4px 4px 100px);
}
  
#logo-criszuca {
    background-image: url('../Assets/bottom.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 70px;
}

@media (min-width: 750px) and (max-width: 1100px) {
    #home{
        gap: 10px;
    }
    #criszuca-img-home {
        width: 350px;
    }
}

@media (min-width: 330px) and (max-width: 750px) {
    #criszuca-img-home {
        width: 120px;
    }

    #logo-criszuca {
        padding: 30px;

    }
    #home{
        padding-top: 20px;
    }
    #titles-home{
        align-items: center !important;
    }

    #titles-home > h2{
        text-align: center !important;
    }
}

@media (max-width: 330px) {
    #criszuca-img-home{
        display: none;
    }

    #logo-criszuca {
        padding: 28px;

    }
    #home{
        padding-top: 20px;
    }
    #titles-home{
        align-items: center !important;
    }
}
.cards{
    filter: hue-rotate(0deg) saturate(1) brightness(1) contrast(1) invert(0) sepia(0) blur(0px) grayscale(0) drop-shadow(rgb(0, 0, 0) 21px 21px 25px);
    border-radius: 14px;
    width: 280px;
    height: 280px;
    background-color: #1d1d1d;
    color: white;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    padding: 20px;
    align-items: start;
}

.container-images{
    opacity: 1;
    border: 0px solid rgb(163, 186, 198);
    filter: hue-rotate(0deg) saturate(1) brightness(1) contrast(1) invert(0) sepia(0) blur(0px) grayscale(0);
    border-radius: 3px;
    background-color: rgb(212, 101, 101);
    width: 40px;
    height: 40px;
}

.title-cards{
    font-size: 16px;
}

.description-cards{
    color: #d4d4d4;
    font-size: 14px;
}